var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "images" },
      [
        _c("image-slider", {
          attrs: {
            images: _vm.images,
            crop: "default",
            "bp-definitions": { lg: "default", sm: "layoutHeaderSm", xs: "xs" },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }