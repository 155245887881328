
























import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import SubPageListing from '../../../Component/Layout/SubPageListing/SubPageListing.vue';

export default {
    name: 'LinkList',
    components: {SubPageListing},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        links()
        {
            return this.context.data.get('links', []);
        },

        isDirectLinks()
        {
            return this.context.data.get('isDirectLinks', []);
        }
    }
};
