


























import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {isArray} from '@labor-digital/helferlein/lib/Types/isArray';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import ImageSlider from '../../../Component/Misc/Image/ImageSlider/ImageSlider.vue';

export default {
    name: 'Images',
    components: {ImageSlider},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        images(): Array<PlainObject>
        {
            const result = this.context.data.get('images', []);
            if (!isArray(result)) {
                return [];
            }
            return result;
        }
    }
};
